import kailuaNew from "../assets/images/locations/kailuaNew.jpg";
import kalakaua from "../assets/images/locations/kalakaua.jpg";
import kapolei from "../assets/images/locations/kapolei.jpg";
import waikiki from "../assets/images/locations/waikiki.jpg";

const locationsList = [
  {
    title: "Sacred Art Tattoo Kailua",
    pic: kailuaNew,
    description: "167 Hamakua Dr, Kailua, HI",
  },
  {
    title: "Sacred Art Tattoo",
    pic: waikiki,
    description: "1831 Ala Moana Blvd, #204, Honolulu, HI",
    link: "https://sacredartwaikiki.com/",
  },
  {
    title: "Sacred Art Tattoo & Coffee Bar",
    pic: kalakaua,
    description: "1925 Kalakaua Ave, Unit A, Honolulu, HI",
    link: "https://sacredarthonolulu.com/",
  },
  {
    title: "Sacred Art Kapolei",
    pic: kapolei,
    description: "563 Farrington Hwy #101, Kapolei, HI",
    link: "https://sacredartkapolei.com/",
  },
];

export default locationsList;
